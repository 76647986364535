import { ApiBurnResponse, BurnProduto, BurnValidatorResponse } from '@/models/burnValidator';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	burnLoginData: {} as BurnValidatorResponse,
	burnLoggedIn: false,
	burnModaltext: '',
	burnModalTitle: '',
	burnModalType: 'ok' as 'ok' | 'options' | 'YN' | 'number' | 'text' | 'onlyText' | 'scan' | 'loading' | 'product',
	burnModalOnSelection: (value: string) => {},
	burnModalOpen: false,
	burnProduct: {} as BurnProduto,
	burnQr: '',
};

const eventsSlice = createSlice({
	name: 'burn',
	initialState,
	reducers: {
		changeBurnLoginData: (state, action) => {
			state.burnLoginData = action.payload;
		},
		changeBurnProduct: (state, action) => {
			state.burnProduct = action.payload;
		},
		changeBurnQr: (state, action) => {
			state.burnQr = action.payload;
		},
		changeBurnLoggedIn: (state, action) => {
			state.burnLoggedIn = action.payload;
		},
		changeBurnModalText: (state, action) => {
			state.burnModaltext = action.payload;
		},
		changeBurnModalTitle: (state, action) => {
			state.burnModalTitle = action.payload;
		},
		changeBurnModalType: (state, action) => {
			state.burnModalType = action.payload;
		},
		changeBurnModalOnSelection: (state, action) => {
			state.burnModalOnSelection = action.payload;
		},
		changeBurnModalOpen: (state, action) => {
			state.burnModalOpen = action.payload;
		},
		resetBurnModal: state => {
			state.burnModaltext = '';
			state.burnModalTitle = '';
			state.burnModalType = 'ok';
			state.burnModalOnSelection = () => {};
			state.burnModalOpen = false;
			state.burnQr = '';
			state.burnProduct = {} as BurnProduto;
		},
		reset: () => initialState,
	},
});

export const { reset, changeBurnLoginData, changeBurnProduct, changeBurnQr, changeBurnLoggedIn, changeBurnModalOnSelection, changeBurnModalOpen, changeBurnModalText, changeBurnModalTitle, changeBurnModalType, resetBurnModal } = eventsSlice.actions;
export default eventsSlice.reducer;
